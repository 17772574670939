var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "template-datas" },
    [
      _c("div", { staticClass: "wrapper" }, [
        _vm.dynamicHeaders.length && !_vm.isLoadingProp
          ? _c(
              "div",
              { staticClass: "wrapper__body" },
              [
                _c("z-pagination", {
                  attrs: {
                    "auto-pagination": false,
                    "icon-previous": "mdi-chevron-left",
                    "icon-next": "mdi-chevron-right",
                    "items-per-page": 20,
                    "number-of-pages": _vm.info.totalPages,
                    "current-page": _vm.info.page,
                    items: _vm.info.data,
                  },
                  on: {
                    "change:page": _vm.alterPageNumber,
                    "click:next": _vm.goPage,
                    "click:previous": _vm.backPage,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "data",
                        fn: function ({ paginatedItems }) {
                          return [
                            _c(
                              "div",
                              { staticClass: "table-wrapper" },
                              [
                                _c("z-table", {
                                  key: _vm.customKey,
                                  staticClass: "table-dynamic",
                                  attrs: {
                                    headers: _vm.dynamicHeaders,
                                    items: paginatedItems,
                                    "icon-next": "mdi-chevron-right",
                                    "icon-previous": "mdi-chevron-left",
                                    "last-fixed-column": "actions",
                                    "last-fixed-column-shadow": 0,
                                  },
                                  on: { sort: _vm.sortTable },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "no-data",
                                        fn: function () {
                                          return [
                                            _c(
                                              "div",
                                              { staticClass: "no-data-table" },
                                              [
                                                _c("h3", [
                                                  _vm._v(
                                                    "Sem dados disponíveis."
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      _vm._l(
                                        _vm.dynamicHeaders,
                                        function (data) {
                                          return {
                                            key: `item.${data.value}`,
                                            fn: function ({ item }) {
                                              return [
                                                data.value == "tags"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        key: data.id,
                                                        staticClass: "d-flex",
                                                        staticStyle: {
                                                          gap: "0.5rem",
                                                          "max-width": "500px",
                                                          "flex-wrap": "wrap",
                                                          padding: "0.5rem 0",
                                                        },
                                                      },
                                                      _vm._l(
                                                        item.tags,
                                                        function (item, idx) {
                                                          return _c(
                                                            "v-chip",
                                                            {
                                                              key: idx,
                                                              staticStyle: {
                                                                padding:
                                                                  "0 0.35rem",
                                                              },
                                                              attrs: {
                                                                small: "",
                                                                color:
                                                                  "primary",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(item) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    )
                                                  : _vm._e(),
                                                data.value != "actions" &&
                                                data.value != "tags"
                                                  ? _c(
                                                      "div",
                                                      { key: data.id },
                                                      [
                                                        !_vm.isEditingRow ||
                                                        _vm.rowToEdit?.id !==
                                                          item.id
                                                          ? _c("span", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    item[
                                                                      data.value
                                                                    ]
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                        _vm.isEditingRow &&
                                                        _vm.rowToEdit?.id ===
                                                          item.id
                                                          ? _c("z-input", {
                                                              attrs: {
                                                                hideDetails: true,
                                                                readonly:
                                                                  data.value.toLowerCase() ==
                                                                    "id" ||
                                                                  data.value.toLowerCase() ==
                                                                    "zooxuserid",
                                                                type: "text",
                                                              },
                                                              model: {
                                                                value:
                                                                  item[
                                                                    data.value
                                                                  ],
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      data.value,
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "item[data.value]",
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                data.value == "actions"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        key: data.id,
                                                        staticClass: "actions",
                                                      },
                                                      [
                                                        !_vm.isEditingRow ||
                                                        _vm.rowToEdit?.id !==
                                                          item.id
                                                          ? _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "primary",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.setItemToEdit(
                                                                        item
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " $edit_rounded"
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm.isEditingRow &&
                                                        _vm.rowToEdit?.id ==
                                                          item.id
                                                          ? _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "primary",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.openConfirmationModal(
                                                                        item
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " $save_rounded "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm.isEditingRow &&
                                                        _vm.rowToEdit?.id ==
                                                          item.id
                                                          ? _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "primary",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      ;(_vm.isEditingRow = false),
                                                                        _vm.resetRowInfo(
                                                                          item
                                                                        )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " $close_rounded "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              color: "primary",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.openDeleteModal(
                                                                  item.zooxuserid
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " $delete_outline_rounded"
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ]
                                            },
                                          }
                                        }
                                      ),
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2919360218
                  ),
                }),
              ],
              1
            )
          : _vm._e(),
        !_vm.dynamicHeaders.length && !_vm.isLoadingProp
          ? _c("div", { staticClass: "wrapper__no-data" }, [
              _c("span", [_vm._v("Sem dados")]),
            ])
          : _vm._e(),
        _vm.isLoadingProp
          ? _c("div", { staticClass: "wrapper__loading" }, [_c("z-loading")], 1)
          : _vm._e(),
      ]),
      _c(
        "v-dialog",
        {
          attrs: { width: "400" },
          model: {
            value: _vm.dialogDelete,
            callback: function ($$v) {
              _vm.dialogDelete = $$v
            },
            expression: "dialogDelete",
          },
        },
        [
          _c(
            "v-form",
            {
              ref: "formDialog",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.saveAndClose.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "dialog",
                  staticStyle: { background: "white", padding: "1rem" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "dialog__title mb-3",
                      staticStyle: { "text-align": "center" },
                    },
                    [
                      _c("strong", [
                        _vm._v("Tem certeza que deseja excluir esses dados?"),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "dialog--actions d-flex align-center justify-center",
                      staticStyle: { gap: "0.9rem" },
                    },
                    [
                      _c("z-btn", {
                        attrs: {
                          text: "Cancelar",
                          color: "primary",
                          rounded: true,
                        },
                        on: { click: _vm.cancelAndReset },
                      }),
                      _c("z-btn", {
                        attrs: {
                          text: "Excluir",
                          primary: "",
                          color: "primary",
                          rounded: true,
                          "is-loading": _vm.loadingSave,
                        },
                        on: { click: _vm.deleteAndClose },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "400" },
          model: {
            value: _vm.dialogSave,
            callback: function ($$v) {
              _vm.dialogSave = $$v
            },
            expression: "dialogSave",
          },
        },
        [
          _c(
            "v-form",
            {
              ref: "formDialog",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.saveAndClose.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "dialog",
                  staticStyle: { background: "white", padding: "1rem" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "dialog__title mb-3",
                      staticStyle: { "text-align": "center" },
                    },
                    [
                      _c("strong", [
                        _vm._v("Tem certeza que deseja atualizar esses dados?"),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "dialog--actions d-flex align-center justify-center",
                      staticStyle: { gap: "0.9rem" },
                    },
                    [
                      _c("z-btn", {
                        attrs: {
                          text: "Cancelar",
                          color: "primary",
                          rounded: true,
                        },
                        on: { click: _vm.cancelAndReset },
                      }),
                      _c("z-btn", {
                        attrs: {
                          text: "Salvar",
                          primary: "",
                          color: "primary",
                          rounded: true,
                          "is-loading": _vm.loadingSave,
                        },
                        on: { click: _vm.saveAndClose },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }